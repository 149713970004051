import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'

import Layout from 'src/components/view/layout'

function IndexPage() {
  const location = useLocation()

  useEffect(() => {
    if (location.host.indexOf('firebaseapp.com') >= 0) {
      // PWA dirty solution
      navigate('/app/admin')
      return
    }
    navigate('/app/dashboard/example-url')
  }, [location])

  return (
    <Layout>
      <div />
    </Layout>
  )
}

export default IndexPage
